import React, { useState } from "react"
import { graphql, Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactPlayer from 'react-player/lazy'
import { motion } from "framer-motion"
import { ArrowElbowRightUp, ArrowElbowRightDown} from "phosphor-react";
import "@fontsource/public-sans/400.css"
import "@fontsource/public-sans/700.css"

import Header from '../components/header'

const Grid = styled.div`
display:grid; 
gap:5px;
grid-template-columns:repeat(auto-fit, minmax(min(100%, 280px), 1fr));
> div {
    transition: all 0.3s ease;
    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
}
.react-player video {
  -o-object-fit: cover;
  object-fit: cover;
}
`

const Drawer = styled.section`
pointer-events: all;
.simplebar-scrollbar::before {
  background-color: rgba(0,0,0,0.33);
}
&[data-active='true'] {
  background-color: rgba(255,255,255,.6);
}
nav, section {
  overflow: hidden;
}
button {
    font-family: 'Faune';
    font-size: 5rem;
    line-height: 1em;
    background: transparent;
    color: rgb(0,0,0);
    border: solid 2px transparent;
    text-align: left;
    z-index: 10;
    position: fixed;
    bottom: 5vh;
    padding-left: 2vw;
    padding-bottom: 8px;
    left: 13vw;
    max-width: 72.5%;
    transition: all 0.3s ease;
    &:hover {
        cursor: pointer;
        color: rgba(0,0,0,0.75);
        border: solid 2px #000;
        background-color: #fff;
    }
    &[data-ison='true'] {
        color: rgb(0,0,0);
        position: fixed;
        visibility: hidden;
    }
    &[data-ison='true'] + div {
        position: fixed;
        transition: bottom 0.3s ease 0.3s;
        visibility: visible;
    }
}
> div {
    position: absolute;
    z-index: 7;
    left: 0;
    bottom: 0;
    padding: 5vh 4vw 5vh 5vw;
    background: rgba(255,255,255,0.95);
    width: 80vw;
    margin-left: 10vw;
    height: 85vh;
    box-sizing: border-box;
    visibility: hidden;
    pointer-events: all;
    transition: visibility 0 linear;
    transition-delay: .3s;
    transition-property: visibility;
    font-family: "Public Sans";
    overflow-y: scroll;
}
h1 {
    font-family: 'Faune';
    font-size: 5rem;
    line-height: 1em;
    position: sticky;
    margin-bottom: 5vh;
    margin-left: -2vw;
    padding-left: 2vw;
    background-color: #fff;
    border: solid 2px transparent;
    width: 72vw;
    top: 0;
    transition: all 0.3s ease;
    &:hover {
        cursor: pointer;
        color: rgba(0,0,0,0.75);
        border: solid 2px #000;
        background-color: #fff;
    }
    svg {
        position: absolute;
        right: 2vw;
        top: 50%;
        transform: translateY(-50%);
    }
}
h3 {
    font-weight: 700;
    font-size: 1.33rem;
    margin-bottom: 1vh;
    margin-top: 7.5vh;
}
p {
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.66em;
    padding-bottom: .75rem;
    font-family: "Public Sans";
    width: 80%;
    strong {
        font-family: 'Faune';
        font-size: 1.3rem;
        border-bottom: solid 1px #000;
    }
    strong em {
      font-family: "Public Sans";
      font-size: 1.1rem;
    }
}
li {
  line-height: 2em;
  strong {
    font-weight: 600;
  }
}
span {
  font-size: .9125rem;
  letter-spacing: 2px;
  font-weight: 800;
}
p.small {
  color: rgba(0,0,0,0.75);
  font-size: .875rem;
}
`

const Content = ({ data }) => {
  const [isOn, setIsOn] = useState(false);
  const toggleSwitch = () => setIsOn(!isOn);
  return (
    <>
        <Header />
            { data.mdx.exports.images && 
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                type: "spring",
                mass: 0.35,
                stiffness: 75,
                delay: 0.3,
                duration: 0.3
              }}
            >
            <Grid>
            {data.mdx.exports.images.map(({ childImageSharp, publicURL, extension, id }) => (
                <div key={id}>

                  {childImageSharp === null && 
                    <a data-fancybox="images" data-width="100%" href={publicURL}>
                    <ReactPlayer
                    url={publicURL}
                    muted={true}
                    loop={true}
                    playing={true}
                    className='react-player'
                    height='100%'
                    width='100%'
                    />
                    </a>
                  }
                  {childImageSharp && <GatsbyImage image={childImageSharp.gatsbyImageData} alt="image" objectFit="contain" data-fancybox="images" />}
              </div>
            ))}
            </Grid>
            </motion.div>
            }
            <Drawer>
            <button onClick={toggleSwitch} data-ison={isOn} >
            {data.mdx.frontmatter.title} <ArrowElbowRightUp size={64} weight="light" />
            </button>
            <motion.div
            animate={{
              y: isOn ? "0vh" : "10vh",
              opacity: isOn ? 1 : 0
            }}
            transition={{ease: "easeIn", duration: 0.3}}
            >
            <h1 onClick={toggleSwitch} data-ison={isOn} >{data.mdx.frontmatter.title} <ArrowElbowRightDown size={64} weight="light" /></h1>
            <MDXRenderer>
            {data.mdx.body}
            </MDXRenderer>
            </motion.div>
            </Drawer>
        </>
    )
}

export const query = graphql`
query ($slug: String) {
  mdx(slug: {eq: $slug}) {
    slug
    id
    frontmatter {
      title
    }
    body
    exports {
      images {
        publicURL
        extension
        id
        childImageSharp {
          gatsbyImageData
          id
        }
      }
    }
  }
}
`

export default Content